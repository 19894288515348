import React from 'react';
import Box from '@sendsprint/ui-react/dist/components/Box';
import { useAppLocation } from '@src/hooks/utils/useAppLocation';
import LinkItem from './LinkItem';
import { linkData } from '../../data/linkData';
import { getActiveLink } from '../../utils/getActiveLink';

const Aside = () => {
  const location = useAppLocation();
  return (
    <Box
      as="aside"
      className="ss-w-max ss-h-max ss-hidden lg:ss-block lg:ss-w-[250px] xl:ss-w-[300px] ss-sticky ss-top-34 ss-bg-white ss-py-8 ss-px-5 ss-rounded-lg">
      <Box className="ss-flex ss-flex-col ss-gap-2">
        {linkData.map((item) => (
          <LinkItem key={item.label} active={getActiveLink(item.to, location.pathname)} {...item} />
        ))}
      </Box>
    </Box>
  );
};

export default Aside;
