import Box from '@sendsprint/ui-react/dist/components/Box';
import Container from '@src/components/container';
import { useAccount } from '@src/contexts';
import { Path } from '@src/navigations/routes';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Aside from './components/aside';
import Header from './components/header';

const DashboardLayout = () => {
  const { isLoggedIn } = useAccount();

  if (!isLoggedIn) {
    return <Navigate to={Path.Login} replace />;
  }

  return (
    <>
      <Box style={{ backgroundColor: '#F6F8FA' }} className="ss-min-h-screen ss-pb-6">
        <Header />
        <Box className="ss-mt-6">
          <Container className="ss-flex ss-gap-8">
            <Aside />
            <Box as="main" className="ss-flex-1">
              <Outlet />
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
