import SuspenseWrapper from '@src/components/suspenseWrapper';
import Loader from '@src/components/loader';
import lazyWithPreload from '@src/utils/lazyWithPreload';
import { RouteObject } from 'react-router-dom';
import { Path } from '../routes';
import Error from '@src/pages/error';
import Box from '@sendsprint/ui-react/dist/components/Box';

export const Dashboard = lazyWithPreload(() => import('@src/pages/user/dashboard'));
export const SendMoney = lazyWithPreload(() => import('@src/pages/user/sendMoney'));
export const Recipients = lazyWithPreload(() => import('@src/pages/user/recipients'));
export const Transactions = lazyWithPreload(() => import('@src/pages/user/transactions'));
export const TransferSchedule = lazyWithPreload(() => import('@src/pages/user/transferSchedule'));
export const AddTransferSchedule = lazyWithPreload(
  () => import('@src/pages/user/addTransferSchedule')
);
export const Connect = lazyWithPreload(() => import('@src/pages/user/connect'));
export const Cards = lazyWithPreload(() => import('@src/pages/user/cards'));
export const Referrals = lazyWithPreload(() => import('@src/pages/user/referrals'));
export const Profile = lazyWithPreload(() => import('@src/pages/user/profile'));

const routesData = [
  { path: Path.Dashboard, component: <Dashboard /> },
  { path: Path.SendMoney, component: <SendMoney /> },
  { path: Path.Recipients, component: <Recipients /> },
  { path: Path.TransferHistory, component: <Transactions /> },
  { path: Path.TransferSchedule, component: <TransferSchedule /> },
  { path: Path.AddTransferSchedule, component: <AddTransferSchedule /> },
  { path: Path.Connect, component: <Connect /> },
  { path: Path.Cards, component: <Cards /> },
  { path: Path.Referrals, component: <Referrals /> },
  { path: Path.Profile, component: <Profile /> }
];

export const dashboardRoutes: RouteObject[] = routesData.map(({ component, path }) => ({
  path,
  element: (
    <SuspenseWrapper
      errorFallback={({ resetError }) => (
        <Box>
          <Error showHeader={false} removeContainer resetError={resetError} />
        </Box>
      )}
      suspenseFallback={<Loader height="content" className="ss-py-[150px]" showTimedOutScreen />}>
      {component}
    </SuspenseWrapper>
  )
}));
