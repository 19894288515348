import { useAccount } from '@src/contexts';
import { Path } from '@src/navigations/routes';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { withCountryBlock } from '@src/components/withCountryBlock/withCountryBlock';

const BLOCKED_COUNTRIES = ['KE'];

const Home = () => {
  const { isLoggedIn } = useAccount();

  if (isLoggedIn) {
    return <Navigate to={Path.Dashboard} replace />;
  }

  return <Navigate to={Path.Login} replace />;
};

export default withCountryBlock(Home, {
  blockedCountries: BLOCKED_COUNTRIES
});

// export default Home;
