import React, { createContext, useContext, useState } from 'react';

interface FixedLayoutContextType {
  fixed: boolean;
  setFixed: (value: boolean) => void;
}

const FixedLayoutContext = createContext<FixedLayoutContextType | undefined>(undefined);

export const FixedLayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [fixed, setFixed] = useState(true);

  return (
    <FixedLayoutContext.Provider value={{ fixed, setFixed }}>
      {children}
    </FixedLayoutContext.Provider>
  );
};

export const useFixedLayout = () => {
  const context = useContext(FixedLayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};
