import React from 'react';
import { useRouteError } from 'react-router-dom';
import Error from '@src/pages/error';
import NotFound from '@src/pages/notFound';

const ErrorBoundary = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = useRouteError() as any;

  if (error?.status === 404) {
    return <NotFound />;
  }

  return <Error resetError={() => undefined} showMeta={false} />;
};

export default ErrorBoundary;
