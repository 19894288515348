import Api from '@sendsprint/api-types';
import ClientApi from '@src/types/client';

// Search param names sent by the form on the website
const SEND_FROM_WEBSITE_SEARCH_PARAM_NAMES = {
  RECEIVE_COUNTRY: 'receiveCountry',
  SEND_COUNTRY: 'sendCountry',
  SEND_AMOUNT: 'sendAmount'
};

/** Get the SendFromWebsiteLocationState if possible */
export const maybeGetSendFromWebsiteLocationState = (
  search: string
): ClientApi.SendFromWebsiteLocationState | undefined => {
  try {
    const searchParams = new URLSearchParams(search);
    const receiveCountry = searchParams.get(
      SEND_FROM_WEBSITE_SEARCH_PARAM_NAMES.RECEIVE_COUNTRY
    ) as Api.Model.CountryInitials | null;

    const sendCountry = searchParams.get(
      SEND_FROM_WEBSITE_SEARCH_PARAM_NAMES.SEND_COUNTRY
    ) as Api.Model.CountryInitials | null;

    const sendAmount = Number(searchParams.get(SEND_FROM_WEBSITE_SEARCH_PARAM_NAMES.SEND_AMOUNT));

    if (
      receiveCountry &&
      sendCountry &&
      searchParams.has(SEND_FROM_WEBSITE_SEARCH_PARAM_NAMES.SEND_AMOUNT) &&
      !Number.isNaN(sendAmount)
    ) {
      return {
        sendFromWebsite: {
          receiveCountry,
          sendCountry,
          sendAmount: Number(sendAmount)
        }
      };
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return;
};
