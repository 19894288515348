import SuspenseWrapper from '@src/components/suspenseWrapper';
import Loader from '@src/components/loader';
import lazyWithPreload from '@src/utils/lazyWithPreload';
import { RouteObject } from 'react-router-dom';
import { Path } from '../routes';
import Error from '@src/pages/error';
import Box from '@sendsprint/ui-react/dist/components/Box';

export const VerifyEmail = lazyWithPreload(() => import('@src/pages/auth/verifyEmail'));
export const ModulrCallback = lazyWithPreload(
  () => import('@src/pages/callbacks/modulrCallbackPage')
);
export const PaymentCallback = lazyWithPreload(
  () => import('@src/pages/callbacks/paymentCallbackPage')
);
export const Veriff = lazyWithPreload(() => import('@src/pages/callbacks/veriff'));
export const PaymentSuccess = lazyWithPreload(() => import('@src/pages/callbacks/paymentSuccess'));

const routesData = [
  { path: Path.VerifyEmail, component: <VerifyEmail /> },
  { path: Path.ModulrCallback, component: <ModulrCallback /> },
  { path: Path.VolumeCallback, component: <PaymentCallback /> },
  { path: Path.StripeCallback, component: <PaymentCallback /> },
  { path: Path.PaymentCallback, component: <PaymentCallback /> },
  { path: Path.Veriff, component: <Veriff /> },
  { path: Path.PaymentSuccess, component: <PaymentSuccess /> }
];

export const generalRoutes: RouteObject[] = routesData.map(({ component, path }) => ({
  path,
  element: (
    <SuspenseWrapper
      errorFallback={({ resetError }) => (
        <Box className="ss--mt-[70px]">
          <Error showHeader={false} removeContainer resetError={resetError} />
        </Box>
      )}
      suspenseFallback={<Loader height="content" className="ss-py-[100px]" showTimedOutScreen />}>
      {component}
    </SuspenseWrapper>
  )
}));
