import { useAccount, useEnv, useToasts } from '@src/contexts';
import { configureInterceptors } from '@src/services/api-service';
import React, { lazy, ReactNode, Suspense, useEffect } from 'react';

const BrowserSupportNotice = lazy(() => import('@src/components/browserSupportNotice'));
const AutoLogout = lazy(() => import('@src/components/autoLogout'));

interface Props {
  children?: ReactNode;
}

const AppLayoutInner = ({ children }: Props) => {
  const { USER_AUTO_LOGOUT_DURATION_MS } = useEnv();
  const { logout } = useAccount();
  const { addToast } = useToasts();

  useEffect(() => {
    return configureInterceptors({ addToast, logout });
  }, [addToast, logout]);

  return (
    <>
      {children}
      <Suspense>
        <BrowserSupportNotice />
      </Suspense>
      <Suspense>
        <AutoLogout inactivityDuration={USER_AUTO_LOGOUT_DURATION_MS} />
      </Suspense>
    </>
  );
};

export default AppLayoutInner;
