import Money from '@sendsprint/ui-react/dist/icons/Money';
import HomeOutline from '@sendsprint/ui-react/dist/icons/HomeOutline';
import { Path } from '@src/navigations/routes';
import CalendarOutline from '@sendsprint/ui-react/dist/icons/CalendarOutline';
import Gift from '@sendsprint/ui-react/dist/icons/Gift';
import { LinkItemProps } from '../components/aside/LinkItem';
import BsPeople from '@src/assets/svgs/transformed/react-icons/bs/BsPeople';
import BsCashStack from '@src/assets/svgs/transformed/react-icons/bs/BsCashStack';

export const linkData: Omit<LinkItemProps, 'active'>[] = [
  {
    icon: HomeOutline,
    label: 'Home',
    to: Path.Dashboard,
    ComponentToPreloadName: 'Dashboard',
    tourIdentifier: 'dashboard'
  },
  {
    icon: Money,
    label: 'Send Money',
    to: Path.SendMoney,
    ComponentToPreloadName: 'SendMoney',
    tourIdentifier: 'send-money'
  },
  {
    icon: CalendarOutline,
    label: 'Schedule Transfer',
    to: Path.TransferSchedule,
    ComponentToPreloadName: 'TransferSchedule',
    tourIdentifier: 'transfer-schedule'
  },
  {
    icon: BsCashStack,
    label: 'Transactions',
    to: Path.TransferHistory,
    isReactIcon: true,
    ComponentToPreloadName: 'Transactions',
    tourIdentifier: 'transactions'
  },
  {
    icon: BsPeople,
    label: 'Recipients',
    to: Path.Recipients,
    isReactIcon: true,
    ComponentToPreloadName: 'Recipients',
    tourIdentifier: 'recipients'
  },
  {
    icon: Gift,
    label: 'Send a Gift',
    to: Path.Connect,
    ComponentToPreloadName: 'Connect',
    tourIdentifier: 'connect'
  }
  // {
  //   icon: Money,
  //   label: 'Cards',
  //   to: Path.Cards
  // }
];
