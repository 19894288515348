import React from 'react';
import { useCountryCheck } from './useCountryCheck';
import { BlockedCountryMessage } from './BlockedCountryMessage';

interface WithCountryBlockConfig {
  blockedCountries: string[];
  LoadingComponent?: React.ComponentType;
  ErrorComponent?: React.ComponentType<{ error: string }>;
}

export const withCountryBlock = (
  WrappedComponent: React.ComponentType,
  config: WithCountryBlockConfig
) => {
  return function WithCountryBlockComponent(props: any) {
    const { blockedCountries, LoadingComponent, ErrorComponent } = config;
    const { isLoading, isAllowed, error, country } = useCountryCheck(blockedCountries);

    if (isLoading) {
      return LoadingComponent ? (
        <LoadingComponent />
      ) : (
        <div className="ss-flex ss-items-center ss-justify-center ss-min-h-screen">
          <div className="text-center">
            <div className="ss-w-16 ss-h-16 ss-border-4 ss-border-blue-500 ss-border-t-transparent ss-rounded-full ss-animate-spin ss-mx-auto"></div>
            <p className="ss-mt-4 ss-text-gray-600">Verifying access...</p>
          </div>
        </div>
      );
    }

    if (error) {
      return ErrorComponent ? (
        <ErrorComponent error={error} />
      ) : (
        <div className="ss-flex ss-items-center ss-justify-center ss-min-h-screen">
          <div className="ss-text-center ss-p-6 ss-bg-red-50 ss-rounded-lg">
            <h2 className="ss-text-red-600 ss-text-xl ss-font-semibold ss-mb-2">Error</h2>
            <p className="ss-text-gray-700">{error}</p>
            <button
              onClick={() => window.location.reload()}
              className="ss-mt-4 ss-px-4 ss-py-2 ss-bg-red-600 ss-text-white ss-rounded hover:ss-bg-red-700">
              Try Again
            </button>
          </div>
        </div>
      );
    }

    if (!isAllowed) {
      return <BlockedCountryMessage country={country} />;
    }

    return <WrappedComponent {...props} />;
  };
};
