import React, { lazy } from 'react';
import Box from '@sendsprint/ui-react/dist/components/Box';
import SuspenseWrapper from '@src/components/suspenseWrapper';

const AsideCarouselInner = lazy(() => import('./AsideCarouselInner'));

const AsideCarousel = () => {
  return (
    <Box className="">
      <SuspenseWrapper returnNullErrorFallback>
        <AsideCarouselInner />
      </SuspenseWrapper>
    </Box>
  );
};

export default AsideCarousel;
