import SuspenseWrapper from '@src/components/suspenseWrapper';
import Loader from '@src/components/loader';
import lazyWithPreload from '@src/utils/lazyWithPreload';
import { RouteObject } from 'react-router-dom';
import { Path } from '../routes';
import Error from '@src/pages/error';
import Box from '@sendsprint/ui-react/dist/components/Box';

export const Login = lazyWithPreload(() => import('@src/pages/auth/login'));
export const Register = lazyWithPreload(() => import('@src/pages/auth/register'));
export const ChangePassword = lazyWithPreload(() => import('@src/pages/auth/changePassword'));
export const ResetPassword = lazyWithPreload(() => import('@src/pages/auth/resetPassword'));

const routesData = [
  { path: Path.Login, component: <Login /> },
  { path: Path.Register, component: <Register /> },
  { path: Path.ChangePassword, component: <ChangePassword /> },
  { path: Path.ResetPassword, component: <ResetPassword /> }
];

export const authRoutes: RouteObject[] = routesData.map(({ component, path }) => ({
  path,
  element: (
    <SuspenseWrapper
      errorFallback={({ resetError }) => (
        <Box className="lg:ss-mr-400 xl:ss-mr-140">
          <Error resetError={resetError} />
        </Box>
      )}
      suspenseFallback={<Loader className="lg:ss-mr-400 xl:ss-mr-140" showTimedOutScreen />}>
      {component}
    </SuspenseWrapper>
  )
}));
