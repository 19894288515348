import { BaseLink } from '@sendsprint/ui-react/dist/components/Link';
import { Path } from '@src/navigations/routes';
import { ReactComponent as Logo } from '@sendsprint/design-system/brand/logos/logo-full-green.svg';
import React from 'react';
import { Outlet } from 'react-router-dom';

const GeneralLayout = () => {
  return (
    <>
      <div>
        <div className="ss-container ss-pt-8 ss-pb-4 ss-text-center">
          <BaseLink to={Path.Home}>
            <Logo className="ss-mx-auto ss-h-10 ss-w-56 md:ss-h-16" role="presentation" />
          </BaseLink>
        </div>
        <div className="ss-container ss-my-10">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default GeneralLayout;
